import { useSelector, shallowEqual } from 'react-redux';
import AccountLinks from './account-links';
import UserImg from '../user/UserImg';
import React from 'react';

const Widget4 = ({ showUsername = true, showType = true }) => {
  const { user } = useSelector(
    (state) => ({
      user: state.user,
    }),
    shallowEqual
  );
  return (
    <>
      <div className="w-full p-4 pb-0">
        <div className="flex flex-col w-full items-center justify-center text-center">
          <div className="rounded-full h-20 w-20 overflow-hidden">
            <UserImg
              image={user.image}
              email={user.email}
              src={user.image}
              alt={user.username}
              className="shadow rounded-full h-20 w-20 border-2 mb-2 border-white"
            />
          </div>
          {/*<img*/}
          {/*    src={`/assets/faces/${user.img}`}*/}
          {/*    alt={user.name}*/}
          {/*  className="shadow rounded-full h-20 w-20 border-2 mb-2"*/}
          {/*/>*/}
          <div className="py-2">
            <p className={` text-sm font-bold ellipsis`}>{user.name}</p>
            {showUsername && (
              <p
                className={`text-xs whitespace-no-wrap text-gray-300 ellipsis`}
              >
                @{user.username}
              </p>
            )}

            {showType && (
              <p
                className={`mt-1 inline-block text-xs whitespace-no-wrap text-gray-300 rounded-lg bg-gray-300 text-brand-dark px-2`}
              >
                {user.type_display}
              </p>
            )}
          </div>
        </div>
      </div>
      <AccountLinks />
    </>
  );
};

export default Widget4;
