import { useSelector, shallowEqual } from 'react-redux';
import AccountLinks from './account-links';
import { CircularBadge } from '../badges';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import UserImg from '../user/UserImg';

const Widget2 = () => {
  const { user } = useSelector(
    (state) => ({
      user: state.user,
    }),
    shallowEqual
  );
  return (
    <>
      <div className="w-full p-4 pb-0">
        <div className="flex items-center justify-start">
          <div className="flex-shrink-0 w-16 relative">
            <UserImg
              image={user.image}
              email={user.email}
              src={user.image}
              alt={user.username}
              className="shadow rounded-full h-16 w-16 border-2 border-brand-light user_img"
            />
            {/*<img*/}
            {/*  src={`/assets/faces/${user.img}`}*/}
            {/*  alt={user.name}*/}
            {/*  className="shadow rounded-full h-16 w-16 border-2"*/}
            {/*/>*/}
            {user.badge && (
              <CircularBadge
                size="sm"
                color={user.badge.color}
                className="absolute"
                style={{ bottom: '-3px', right: '3px' }}
              >
                {user.badge.text}
              </CircularBadge>
            )}
          </div>
          <div className="ltr:ml-3 rtl:mr-3 py-2 user_text">
            <p className={` text-sm font-bold whitespace-no-wrap`}>
              {user.name}
            </p>
            <p className={`text-sm whitespace-no-wrap mb-1 capitalize`}>
              {user.type}
            </p>

            <div className="flex flex-row items-center justify-start w-full children-x-2 text-gray-500 text-xs">
              <FontAwesomeIcon icon={faMapMarkerAlt} size="sm" />
              {user.location}
              {/*<a href="https://www.twitter.com" target="_blank" ><i className={`text-lg icon-social-twitter`}></i></a>*/}
              {/*<a href="https://www.facebook.com" target="_blank" ><i className={`text-lg icon-social-facebook`}></i></a>*/}
              {/*<a href="https://www.instagram.com" target="_blank" ><i className={`text-lg icon-social-instagram`}></i></a>*/}
            </div>
          </div>
        </div>
      </div>
      <AccountLinks />
    </>
  );
};

export default Widget2;
